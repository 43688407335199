import React from 'react'
import Select from 'react-select'
import { ValueType } from 'react-select/lib/types'
import { TaskObj } from '../../../../objects/tasks'

import {
  selectStyle,
  SelectOptionType,
  IndicatorSeparator,
  DropdownIndicator
} from './taskAssigneeSelect'

export const StatusSelect: React.FC<{
  headerTitle: string
  optionData: any
  onChangeFunc(value: ValueType<SelectOptionType>): void
  selectedOption?: ValueType<SelectOptionType>
  activeTask: TaskObj
}> = (props) => {
  const { activeTask, selectedOption, optionData } = props
  let defaultValue: ValueType<SelectOptionType> = selectedOption || null

  if (activeTask.id !== 'NEW' && !selectedOption) {
    defaultValue =
      optionData[
        activeTask.status === 'Not Started' ||
        activeTask.status === 'In Progress'
          ? 0
          : 1
      ]
  }

  return (
    <React.Fragment>
      <div className='task-add__label'>{props.headerTitle}</div>
      <Select
        isSearchable={false}
        styles={selectStyle()}
        components={{ IndicatorSeparator, DropdownIndicator }}
        onChange={props.onChangeFunc}
        options={props.optionData}
        placeholder={'Select status'}
        value={defaultValue}
      />
    </React.Fragment>
  )
}
