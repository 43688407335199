import React, { Component } from 'react'
import TaskItem from './taskItem'
import TaskNull from './taskNull'
import { TaskObj, TaskView } from '../../../objects/tasks'

interface TasksListProps {
  tasks: TaskObj[]
  householdFinId: string
  isEditModalOpen?: boolean
  openEditTaskModal?(selectedTask: TaskObj): void
  activeTaskId?: string
  hasNoFilterResult?: boolean
  isTasksEmpty?: boolean
  taskView: TaskView
}

class TasksList extends Component<TasksListProps> {
  public render() {
    const {
      tasks,
      activeTaskId,
      isTasksEmpty,
      hasNoFilterResult,
      isEditModalOpen,
      householdFinId,
      openEditTaskModal,
      taskView
    } = this.props
    // Check if tasks are an array with data
    if (!tasks || tasks.length === 0) {
      return (
        <TaskNull
          isTasksEmpty={isTasksEmpty}
          hasNoFilterResult={hasNoFilterResult}
          taskView={taskView}
        />
      )
    }
    return tasks.map((task, i) => {
      return (
        <TaskItem
          key={i}
          activeTaskId={activeTaskId}
          task={task}
          hasPic={false}
          isEditModalOpen={isEditModalOpen}
          householdFinId={householdFinId}
          openEditTaskModal={openEditTaskModal}
        />
      )
    })
  }
}

export default TasksList
