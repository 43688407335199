/* eslint-disable complexity */
import { TaskView } from '../../../objects/tasks'
import * as SortHelper from '../../helpers/householdPreferences'

export const translateSort = (label: string) => {
  switch (label) {
    case 'DESCRIPTION':
      return 'subject'
    case 'ASSIGNED':
      return 'assigneeNames'
    case 'DUE DATE':
      return 'dueDate'
    case 'SHOW/HIDE':
      return 'includeInGuidebook'
    case 'STATUS':
      return 'status'
    default:
      return null
  }
}

export const sortTaskItems = (tasks: any, state: any, taskView: TaskView) => {
  const { sortBy, sortDescending, stickNullToBottom = false } = state
  const sort = translateSort(sortBy)
  const sortedTasks = {}
  if (tasks) {
    Object.keys(tasks)
      .sort((a, b) => {
        let sortA = tasks[a][sort] ? tasks[a][sort] : ''
        let sortB = tasks[b][sort] ? tasks[b][sort] : ''
        if (stickNullToBottom) {
          if (!sortA) return 1
          if (!sortB) return -1
        }
        if (sortBy === 'DESCRIPTION') {
          return SortHelper.compareStringField(sortA, sortB, sortDescending)
        }
        if (sortBy === 'DUE DATE' || sortBy === 'ASSIGNED') {
          return SortHelper.compareStringWithBlankField(
            sortA,
            sortB,
            sortDescending
          )
        }
        if (sortBy === 'SHOW/HIDE') {
          return SortHelper.compareNumericField(sortA, sortB, !sortDescending)
        }
        if (sortBy === 'STATUS') {
          sortA = tasks[a].status ? tasks[a].status : ''
          if (!tasks[a].crmId || !tasks[a].isCrmTask) {
            if (taskView === 'open') {
              if (tasks[a].status !== 'Completed') {
                sortA = 'Not Completed'
              }
            } else {
              sortA = 'Completed'
            }
          }
          sortB = tasks[b].status ? tasks[b].status : ''
          if (!tasks[b].crmId || !tasks[b].isCrmTask) {
            if (taskView === 'open') {
              if (tasks[b].status !== 'Completed') {
                sortB = 'Not Completed'
              }
            } else {
              sortB = 'Completed'
            }
          }
          return SortHelper.compareStringField(sortA, sortB, sortDescending)
        } else return null
      })
      .forEach((key: string) => {
        sortedTasks[key] = tasks[key]
      })
  }
  return sortedTasks
}
