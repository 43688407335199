import { ClientTask } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export interface TaskObj {
  id: string
  lifeActionTaskId?: string
  crmId: string
  crmSource: string
  subject: string
  description: string
  dueDate: string
  createdDate: string
  completedDate: string
  status: string
  assigneeType: string
  assigneeNames: string
  includeInGuidebook: boolean
  advisorAssignee?: string
  primaryAssignee?: string
  secondaryAssignee?: string
  isClosed?: boolean
  lastModifiedDate?: string
  crmTaskId?: string
  showInGuidecenter?: boolean
  isCrmTask?: boolean
  lastSynced?: string
}

export const emptyTaskObj: TaskObj = {
  id: null,
  crmId: null,
  crmSource: null,
  subject: null,
  description: null,
  dueDate: null,
  createdDate: null,
  completedDate: null,
  status: null,
  assigneeType: null,
  assigneeNames: null,
  includeInGuidebook: false,
  advisorAssignee: null,
  primaryAssignee: null,
  secondaryAssignee: null,
  isClosed: null,
  lastModifiedDate: null
}

export interface TaskObjState {
  [key: string]: TaskObj
}

export interface TaskInterface {
  tasks: TaskObjState
  openTasks?: TaskObjState
  completedTasks?: TaskObjState
}

export interface TaskInterfaceV2 extends TaskInterface {
  sideBarState: boolean
  selectedTaskId?: null
  viewMode: boolean
  taskRequestId: string
  activeTask?: TaskObj
  disableTask?: boolean
}

export interface TaskStatusSelectionObj {
  value: string
  label: string
  field: string
}

export const TaskStatusSelection: TaskStatusSelectionObj[] = [
  { value: 'In Progress', label: 'Not Completed', field: 'completedDate' },
  { value: 'Completed', label: 'Completed', field: 'completedDate' }
]

export interface TaskResponse extends ClientTask.Response {
  householdId: string
  clientTaskId?: string
  crmTasks?: TaskObj[]
}

export type TaskView = 'open' | 'completed'

export type ClientsTaskNotificationStatus = {
  [key: string]: SingleClientNotificationStatus
}

export type SingleClientNotificationStatus = {
  key: 'primary' | 'secondary'
  name: string
  enabled: boolean
  assigned: boolean
}
