import { GlobalState } from '../../reducers'
import { createDeepEqualSelector } from './selectorCreator'
import { TaskInterfaceV2, TaskObj } from '../../objects/tasks'

export const getAllTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.tasks[householdFinId]
}

export const getAllNativeTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.tasks[householdFinId]
}

export const getAllCRMTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.crmTasks[householdFinId]
}

export const combineTasks = createDeepEqualSelector(
  getAllNativeTasks,
  getAllCRMTasks,
  (nativeTasks, crmTasks) => {
    return (
      nativeTasks &&
      crmTasks && {
        ...nativeTasks,
        tasks: { ...nativeTasks.tasks, ...crmTasks.tasks }
      }
    )
  }
)

// split tasks into all, open, and completedTasks
const mapTaskStatuses = (tasks: TaskInterfaceV2) => {
  if (tasks?.tasks) {
    const openTaskObj = {}
    const completedTaskObj = {}
    Object.values(tasks.tasks).forEach((task: TaskObj) => {
      if (task.crmId || task.isCrmTask) {
        task.isClosed
          ? (completedTaskObj[task.id] = task)
          : (openTaskObj[task.id] = task)
      } else {
        task.status === 'Completed'
          ? (completedTaskObj[task.id] = task)
          : (openTaskObj[task.id] = task)
      }
    })
    tasks.openTasks = openTaskObj
    tasks.completedTasks = completedTaskObj
  }
  return tasks
}

export const tasksSelector = createDeepEqualSelector(
  [getAllTasks],
  (tasks): TaskInterfaceV2 => mapTaskStatuses(tasks)
)

export const tasksSelectorV2 = createDeepEqualSelector(
  combineTasks,
  (tasks): TaskInterfaceV2 => mapTaskStatuses(tasks)
)
