import { TaskObjState, TaskView } from '../../../objects/tasks'

export const applyStatusFilterHelper = (
  taskView: TaskView,
  selectedStatuses: string[],
  tasks?: TaskObjState
) => {
  if (!selectedStatuses?.length) {
    return tasks
  }
  const tasksFilteredByAssignee = {}
  tasks &&
    Object.keys(tasks).forEach((task) => {
      let status = tasks[task].status
      if (!tasks[task].crmId || !tasks[task].isCrmTask) {
        if (taskView === 'open') {
          if (tasks[task].status !== 'Completed') {
            status = 'Not Completed'
          }
        } else {
          status = 'Completed'
        }
      }
      Object.keys(selectedStatuses).forEach((e) => {
        if (selectedStatuses[e] === status) {
          tasksFilteredByAssignee[task] = tasks[task]
        }
      })
    })

  return tasksFilteredByAssignee
}
