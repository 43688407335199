import React, { Fragment, ChangeEvent } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'

import { sortTaskItems } from '../../helpers/sort/taskSort'

import { applyAssigneeFilterHelper } from '../../helpers/taskFilters/assigneeFilter'
import { applyStatusFilterHelper } from '../../helpers/taskFilters/statusFilter'
import { applyTaskTypeFilterHelper } from '../../helpers/taskFilters/taskTypeFilter'

import {
  filterDisableTasks,
  syncFetchTasks,
  filterNullTasks
} from '../../helpers/tasks'

import { getTasks } from '../../../actions/tasks'
import { taskRefetch } from '../../helpers/taskActionHelpers'
import { GlobalState } from '../../../reducers'
import { TaskObj, TaskObjState, TaskView } from '../../../objects/tasks'

import TasksList from './tasksList'
import TableHeader from './tableHeader'
import Button from '../../components/button'
import Tile from '../../components/layout/tile'
import ContentHeader from '../../components/layout/contentHeader'
import Search from '../../components/search'

import TasksIcon from '../../assets/images/icons/png/tasks_grey.png'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import {
  updateGlobalNavigation,
  updateSideBarState
} from '../../../actions/globalSettings'
import { getOfficeTeamByHousehold } from '../../../actions/households'
import { HouseholdObj } from '../../../objects/household'

import TaskFilter from './tasksFilter'
import { setTaskFilters } from '../../../actions/taskFilter'
import { tasksSelector, tasksSelectorV2 } from '../../../selectors/v3/tasks'

interface TasksState {
  taskView: TaskView
  sortBy: string
  sortDescending: boolean
  offset: number
  searchFilter: string
  showFilterDropdown: boolean
}

interface TasksProps {
  location?: any
  dispatch: Dispatch<GlobalState>
  disableTasks: boolean
  householdFinId: string
  openTasks: TaskObjState
  completedTasks: TaskObjState
  tasks: TaskObjState
  urlTaskId?: string
  sideBarState: boolean
  activeTaskId: string
  household: HouseholdObj
  history: any
  showNativeTaskType: boolean
  showLinkedTaskType: boolean
  selectedStatusType: string[]
  selectedAssignees: string[]
  isFinlifeInstitution?: boolean
  enableCRMTasks?: boolean
}

const NUMBER_OF_NEW_ROWS = 10

class Tasks extends React.Component<TasksProps, TasksState> {
  constructor(props: TasksProps) {
    super(props)
    this.state = {
      taskView: 'open',
      sortBy: 'DUE DATE',
      sortDescending: false,
      offset: 0,
      searchFilter: '',
      showFilterDropdown: false
    }
  }

  public componentDidMount = async () => {
    const {
      householdFinId,
      dispatch,
      disableTasks,
      household,
      isFinlifeInstitution,
      enableCRMTasks
    } = this.props
    const isTasksV2Enabled = window._env_.REACT_APP_CONNECT_TO_TASKS_V2
    if (disableTasks) {
      const getTasksAction =
        isTasksV2Enabled === 'true' && !isFinlifeInstitution && enableCRMTasks
          ? taskRefetch(householdFinId)
          : dispatch(getTasks(householdFinId))
      await getTasksAction
    } else {
      await syncFetchTasks(
        householdFinId,
        dispatch,
        isFinlifeInstitution,
        enableCRMTasks,
        isTasksV2Enabled
      )
    }
    if (!household?.officeTeams?.length) {
      await dispatch(getOfficeTeamByHousehold(householdFinId))
    }
    this.handleUrlTaskId()
  }

  public componentDidUpdate = async (prevProps: TasksProps) => {
    const {
      householdFinId,
      dispatch,
      disableTasks,
      household,
      location,
      isFinlifeInstitution,
      enableCRMTasks
    } = this.props
    const isTasksV2Enabled = window._env_.REACT_APP_CONNECT_TO_TASKS_V2
    if (
      householdFinId !== prevProps.householdFinId ||
      location?.pathname !== prevProps.location?.pathname
    ) {
      if (disableTasks) {
        const getTasksAction =
          isTasksV2Enabled === 'true' && !isFinlifeInstitution && enableCRMTasks
            ? taskRefetch(householdFinId)
            : dispatch(getTasks(householdFinId))
        await getTasksAction
      } else {
        await syncFetchTasks(
          householdFinId,
          dispatch,
          isFinlifeInstitution,
          enableCRMTasks,
          isTasksV2Enabled
        )
      }
      if (!household?.officeTeams?.length) {
        await dispatch(getOfficeTeamByHousehold(householdFinId))
      }
      this.handleUrlTaskId()
    }
  }

  public componentWillUnmount() {
    const { householdFinId: householdId, dispatch } = this.props
    dispatch({
      type: 'TASK_MODE',
      payload: { data: { householdId, mode: '' } }
    })
    dispatch(updateSideBarState({ sideBarState: false, householdId }))
  }

  public updateFilter = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchFilter: e.currentTarget.value.toLowerCase() })
  }

  public handleScrollTable = (e: React.UIEvent<HTMLDivElement>) => {
    const { offset } = this.state
    // leave a 20 pixel buffer for a smoother experience
    const BUFFER = 20
    const bottom =
      e.currentTarget.scrollHeight -
        Math.ceil(e.currentTarget.scrollTop + BUFFER) <=
      e.currentTarget.clientHeight
    if (bottom) {
      this.setState({ offset: offset + NUMBER_OF_NEW_ROWS })
    }
  }

  public handleUrlTaskId = () => {
    const {
      urlTaskId,
      openTasks,
      completedTasks,
      history,
      householdFinId
    } = this.props
    const { taskView } = this.state
    const tasksToDisplay = taskView === 'open' ? openTasks : completedTasks
    if (urlTaskId && tasksToDisplay && tasksToDisplay[urlTaskId]) {
      this.openEditTaskModal(tasksToDisplay[urlTaskId])()
      history.push(`/households/${householdFinId}/tasks`)
    }
  }

  public toggleAddTaskModal = () => {
    const { dispatch, householdFinId, disableTasks } = this.props
    this.toggleSidebarAndNav(true)
    dispatch({
      type: 'TASK_MODE',
      payload: {
        data: {
          householdId: householdFinId,
          mode: 'ADD',
          disableTasks
        }
      }
    })
  }

  public openEditTaskModal = (selectedTask: TaskObj) => (event?: any) => {
    const { dispatch, householdFinId, disableTasks } = this.props

    if (!selectedTask) return
    if (event?.target?.id === 'toggle' || event?.target?.id === 'toggleInput') {
      return
    }
    if (selectedTask?.assigneeType === 'client') {
      this.toggleSidebarAndNav(true)
      dispatch({
        type: 'TASK_MODE',
        payload: {
          data: {
            householdId: householdFinId,
            mode: 'EDIT',
            activeTask: selectedTask,
            disableTasks
          }
        }
      })
    } else this.closeEditModal()
  }

  public closeEditModal = () => {
    const { dispatch, householdFinId } = this.props
    this.toggleSidebarAndNav(false)
    dispatch({
      type: 'TASK_MODE',
      payload: { data: { householdId: householdFinId, mode: '' } }
    })
  }

  public toggleSidebarAndNav = (state: boolean) => {
    const { dispatch, householdFinId } = this.props
    dispatch(
      updateSideBarState({ sideBarState: state, householdId: householdFinId })
    )
    dispatch(updateGlobalNavigation(state))
  }

  // Right Header
  public rightHeaderActions = () => {
    const { taskView } = this.state
    return (
      <Fragment>
        <Button
          onClick={this.toggleTaskList('open')}
          clear={true}
          style={{
            borderRadius: 0,
            fontSize: '14px',
            textDecoration: 'none',
            color: taskView === 'open' ? '#255EBA' : '#231F20',
            borderBottom: taskView === 'open' ? 'solid 2px #255EBA' : 'none'
          }}>
          Open
        </Button>
        <Button
          onClick={this.toggleTaskList('completed')}
          clear={true}
          style={{
            borderRadius: 0,
            fontSize: '14px',
            textDecoration: 'none',
            color: taskView === 'completed' ? '#255EBA' : '#231F20',
            borderBottom:
              taskView === 'completed' ? 'solid 2px #255EBA' : 'none'
          }}>
          Completed
        </Button>
        <Button onClick={this.toggleAddTaskModal} primary={true}>
          <PlusIcon /> Add Task
        </Button>
      </Fragment>
    )
  }

  public searchField = () => {
    return (
      <Search onChange={this.updateFilter} value={this.state.searchFilter} />
    )
  }

  public toggleTaskList = (selection: TaskView) => () => {
    const { dispatch, householdFinId, sideBarState } = this.props
    // If Task Side Bar is open, let's close it
    if (sideBarState === true) {
      dispatch(
        updateSideBarState({ sideBarState: false, householdId: householdFinId })
      )
      dispatch({
        type: 'TASK_MODE',
        payload: { data: { householdId: householdFinId, mode: '' } }
      })
    }
    // Apply default filter when switching tabs
    dispatch(setTaskFilters(true, true, [], []))
    this.setState({ taskView: selection })
  }

  public handleFilterModalToggle = () => {
    this.setState((prevState) => ({
      ...prevState
    }))
  }

  public setSort = () => (sortDescending: boolean, sortBy: string) => {
    this.setState({ sortBy, sortDescending })
  }

  public toggleFilterDropdown = () => {
    this.setState({ showFilterDropdown: !this.state.showFilterDropdown })
  }

  public onApply = () => {
    this.setState({ offset: 0, showFilterDropdown: false })
  }

  public applyFilter = (taskView: TaskView, tasks: TaskObjState) => {
    const {
      showNativeTaskType,
      showLinkedTaskType,
      selectedStatusType,
      selectedAssignees
    } = this.props
    const filteredTasks = applyAssigneeFilterHelper(
      selectedAssignees,
      applyStatusFilterHelper(
        taskView,
        selectedStatusType,
        applyTaskTypeFilterHelper(showNativeTaskType, showLinkedTaskType, tasks)
      )
    )

    return filteredTasks
  }

  public render() {
    const {
      sortBy,
      sortDescending,
      searchFilter,
      showFilterDropdown,
      offset,
      taskView
    } = this.state
    const {
      openTasks,
      completedTasks,
      disableTasks,
      sideBarState,
      householdFinId,
      activeTaskId
    } = this.props
    // Convert tasks into array for scrolling
    const tasksArray: TaskObj[] = []
    const tasksForFilter: TaskObj[] = []
    const tasksToDisplay = taskView === 'open' ? openTasks : completedTasks
    const filteredTasks = sortTaskItems(
      filterNullTasks(filterDisableTasks(tasksToDisplay, disableTasks)),
      { sortBy, sortDescending },
      taskView
    )
    Object.keys(this.applyFilter(taskView, filteredTasks)).map((key) => {
      const task = tasksToDisplay[key]
      tasksArray.push(task)
    })
    Object.keys(filteredTasks).map((key) => {
      const task = tasksToDisplay[key]
      tasksForFilter.push(task)
    })
    const searchedTasks = tasksArray.filter((tasks: TaskObj) => {
      if (tasks?.subject == null) {
        tasks.subject = ''
      }
      return tasks?.subject?.toLowerCase().includes(searchFilter)
    })
    const isTasksEmpty = Object.keys(filteredTasks).length === 0
    const hasNoFilterrResult = tasksArray.length === 0
    return (
      <div className='task__body'>
        <div className={'task__body-table task__body-table--closed'}>
          <ContentHeader
            title={[
              <img
                className='content-header__img'
                src={TasksIcon}
                alt=''
                role='presentation'
                key='icon'
              />,
              <div key='title'>Tasks</div>
            ]}
            hasFilter={true}
            onFilterClick={this.toggleFilterDropdown}
            rightHeader={this.rightHeaderActions()}
          />
          {showFilterDropdown && (
            <TaskFilter
              toggleFilterDropdown={this.toggleFilterDropdown}
              onApply={this.onApply}
              handleFilterToggle={this.handleFilterModalToggle}
              disableTasks={disableTasks}
              taskView={taskView}
              tasks={tasksForFilter}
            />
          )}
          <Tile
            headerStyle={{ padding: '0px 17px 0px 17px' }}
            leftHeader={this.searchField()}
            headerBorder={true}>
            <TableHeader
              sortBy={sortBy}
              sortDescending={sortDescending}
              setSort={this.setSort}
            />
            <div onScroll={this.handleScrollTable} className='task__scroll'>
              <TasksList
                tasks={searchedTasks.slice(0, offset + NUMBER_OF_NEW_ROWS)}
                activeTaskId={activeTaskId}
                isEditModalOpen={sideBarState}
                householdFinId={householdFinId}
                openEditTaskModal={this.openEditTaskModal}
                hasNoFilterResult={hasNoFilterrResult}
                isTasksEmpty={isTasksEmpty}
                taskView={taskView}
              />
            </div>
          </Tile>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { match, history, location }: any
) => {
  const { householdFinId, taskId } = match?.params
  const taskFilter = store.taskFilter
  const { isFinlifeInstitution } = store?.institution
  const tasks =
    window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true'
      ? // if isFinlifeInstitutionis true send native tasks to component else both crm and native tasks
        isFinlifeInstitution
        ? tasksSelector(store, householdFinId)
        : tasksSelectorV2(store, householdFinId)
      : tasksSelector(store, householdFinId)
  return {
    disableTasks: store.institution.disableTasks,
    householdFinId,
    urlTaskId: taskId,
    history,
    location,
    household: store.households?.[householdFinId],
    activeTaskId: tasks?.activeTask?.id,
    sideBarState: tasks?.sideBarState || false,
    showNativeTaskType: taskFilter.showNativeTaskType,
    showLinkedTaskType: taskFilter.showLinkedTaskType,
    selectedStatusType: taskFilter.selectedStatuses,
    selectedAssignees: taskFilter.selectedAssignees,
    openTasks: tasks?.openTasks || null,
    completedTasks: tasks?.completedTasks || null,
    tasks: tasks?.tasks,
    isFinlifeInstitution: store?.institution.isFinlifeInstitution,
    enableCRMTasks: store?.institution.enableCRMTasks
  }
}

export default withRouter(connect(mapStateToProps)(Tasks))
