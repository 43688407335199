import React, { Component } from 'react'
import _ from 'lodash'
import { TaskView1 } from './steps'
import { withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { TaskInterface, TaskObj } from '../../../objects/tasks'
import Button from '../../components/button'
import Modal from '../../components/layout/modal'
import { addToast } from '../../../actions/toasts'
import { SideBar } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ReactComponent as TaskIcon } from '../../assets/images/icons/tasks.svg'
import { createTaskById, deleteTask, updateTask } from '../../../actions/tasks'
import {
  updateGlobalNavigation,
  updateSideBarState
} from '../../../actions/globalSettings'

import { TaskTitle } from './inputs/tasksAssignType'

interface SideBarViewProps {
  taskId: string
  tasks: TaskInterface
  householdFinId: string
  viewMode: string
  activeTask: TaskObj
  showSideBar: boolean
  dispatch: Dispatch<GlobalState>
  onCancel(): void
  globalNavigationState: boolean
  sideBarState: boolean
  disableTask: boolean
}
interface SideBarViewState {
  activeTask: any
  viewKey: number
  globalNavState: boolean
  modalToggle: boolean
  isDispatching: boolean
}

export class SideBarView extends Component<SideBarViewProps, SideBarViewState> {
  viewRef: any
  constructor(props: SideBarViewProps) {
    super(props)
    this.state = {
      activeTask: {},
      viewKey: 0,
      globalNavState: false,
      modalToggle: false,
      isDispatching: false
    }
    this.viewRef = React.createRef()
  }

  public componentDidMount() {
    const { globalNavigationState } = this.props
    this.setState({
      globalNavState: globalNavigationState
    })
  }

  public componentDidUpdate(prevProps: SideBarViewProps) {
    const { showSideBar, activeTask } = this.props
    if (
      this.props.globalNavigationState !== this.state.globalNavState &&
      !this.props.sideBarState
    ) {
      this.setState({
        globalNavState: this.props.globalNavigationState
      })
    }
    if (activeTask?.id !== prevProps.activeTask?.id) {
      if (showSideBar) {
        this.setState({
          viewKey: 1
        })
      }
    }
  }

  public showToast = (message: string) => {
    const { dispatch } = this.props
    dispatch(
      addToast({
        icon: TaskIcon,
        message,
        backgroundColor: '#D9F2B6'
      })
    )
  }

  public dispatchTaskActions = (activeTask: TaskObj, viewMode: string) => {
    const { householdFinId, dispatch } = this.props
    this.setState({ isDispatching: true }, async () => {
      if (viewMode === 'ADD') {
        await dispatch(createTaskById(householdFinId, { ...activeTask }))
      } else if (viewMode === 'EDIT') {
        await dispatch(
          updateTask(householdFinId, activeTask.id, {
            ...activeTask,
            assigneeType: 'client',
            ...{ householdId: householdFinId }
          })
        )
      }
      if (viewMode === 'ADD' || viewMode === 'EDIT') {
        this.showToast(viewMode === 'ADD' ? 'Task Created' : 'Task Updated')
      }
      this.setState({ isDispatching: false })
    })
  }

  public onPrimaryBtnClick = async () => {
    await this.viewRef?.wrappedInstance?.syncWithStore()
    const {
      householdFinId,
      activeTask,
      dispatch,
      onCancel,
      viewMode
    } = this.props

    const { isDispatching } = this.state

    !isDispatching && this.dispatchTaskActions(activeTask, viewMode)
    dispatch(updateGlobalNavigation(this.state.globalNavState))
    await dispatch(
      updateSideBarState({ householdId: householdFinId, sideBarState: false })
    )

    this.resetViewMode()
    onCancel && onCancel()
  }

  public getView = () => {
    const { viewKey } = this.state
    const { viewMode, activeTask, dispatch, householdFinId } = this.props
    // const preTitle = activeTask.assigneeType === 'client' ? 'Client ' : ''
    let ComponentView!: any
    let config = {}
    let componentProps = {}

    switch (viewKey) {
      case 1:
        config = {
          ...config,
          ...{
            header: {
              hideActions: false,
              actions: [],
              title: (
                <TaskTitle
                  titleName={
                    viewMode === 'ADD' ? 'ADD NEW TASK' : `TASK DETAILS`
                  }
                />
              )
            },
            footer: {
              primaryBtnAction: this.onPrimaryBtnClick
            }
          }
        }
        componentProps = {
          ...componentProps,
          ...{
            activeTask,
            dispatch,
            householdFinId,
            viewMode,
            setViewKey: this.setViewKey,
            deleteTask: this.toggleDeleteModal
          }
        }
        ComponentView = TaskView1
        break
      default:
        ComponentView = () => {
          return ''
        }
    }
    return { config, ComponentView, componentProps }
  }

  public setViewKey = (viewKey: number) => {
    this.setState({ viewKey })
  }

  public toggleDeleteModal = () => {
    this.setState({ modalToggle: !this.state.modalToggle })
  }

  public handleTaskDelete = async () => {
    const { householdFinId, dispatch, activeTask } = this.props
    await dispatch(deleteTask(householdFinId, activeTask.id))
    await this.toggleDeleteModal()
    this.showToast('Task Deleted')
    dispatch(updateGlobalNavigation(this.state.globalNavState))
    await dispatch(
      updateSideBarState({ householdId: householdFinId, sideBarState: false })
    )
    this.resetViewMode()
  }

  public onCancelSideBar = async () => {
    const { dispatch, onCancel, householdFinId } = this.props
    dispatch(updateGlobalNavigation(this.state.globalNavState))
    await dispatch(
      updateSideBarState({ householdId: householdFinId, sideBarState: false })
    )
    this.resetViewMode()
    onCancel && onCancel()
  }

  public resetViewMode = () => {
    const { dispatch, householdFinId } = this.props
    setTimeout(
      () =>
        dispatch({
          type: 'TASK_MODE',
          payload: { data: { householdId: householdFinId, mode: '' } }
        }),
      500
    )
  }

  public getSideBarConfig = () => {
    const { viewKey } = this.state
    const { activeTask } = this.props
    const { ComponentView, config, componentProps } = this.getView()

    const primaryBtnDisabled = (): boolean => {
      return !activeTask?.subject?.length || !activeTask?.assigneeNames?.length
    }

    return _.merge(
      {
        onCancel: this.onCancelSideBar,
        header: {
          title: 'ADD NEW TASK',
          showBackBtn: false,
          hideActions: true,
          actions: null
        },
        component: ComponentView,
        componentProps: {
          ...(viewKey !== 0
            ? {
                ref: (ref: any) => {
                  this.viewRef = ref
                }
              }
            : {}),
          ...{
            taskCls: 'side-bar-body__'
          },
          ...componentProps
        },
        footer: {
          isBtnDisabled: primaryBtnDisabled(),
          primaryBtnText: 'Save',
          primaryBtnAction: null,
          secondaryBtnText: 'Cancel'
        },
        classNames: `side-bar-body__w`
      },
      config
    )
  }

  public renderDeleteModal = () => {
    return (
      <Modal title='Delete Task' closeModal={this.toggleDeleteModal}>
        <div className='side-bar-body__delete-text'>
          Are you sure you want to delete this task?
        </div>
        <div className='side-bar-body__delete-button-w'>
          <Button clear={true} onClick={this.toggleDeleteModal}>
            Cancel
          </Button>
          <Button remove={true} onClick={this.handleTaskDelete}>
            Delete
          </Button>
        </div>
      </Modal>
    )
  }

  public render() {
    const { activeTask: { id } = {}, showSideBar } = this.props
    return (
      <>
        <div
          className={`side-bar-body__panel side-bar-body__panel--${
            showSideBar ? `open` : `closed`
          } `}>
          <SideBar key={id} {...this.getSideBarConfig()} />
        </div>
        {this.state.modalToggle && this.renderDeleteModal()}
      </>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match?.params?.householdFinId
  const houseTaskActions = store?.tasks[householdFinId]
  return {
    contacts: store.contact[householdFinId],
    householdFinId,
    tasks: houseTaskActions?.tasks || {},
    taskId: houseTaskActions?.taskRequestId || null,
    disableTask: houseTaskActions?.disableTask || false,
    viewMode: houseTaskActions?.viewMode,
    activeTask: houseTaskActions?.activeTask || {},
    globalNavigationState: store?.globalSettings?.globalNavigation?.collapsed,
    sideBarState: houseTaskActions && houseTaskActions.sideBarState
  }
}

export default withRouter(connect(mapStateToProps)(SideBarView))
