import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { TaskObj } from '../../../../objects/tasks'

export const TaskTextArea: React.FC<{
  id: string
  title: string
  subject: string
  assigneeNames: string
  activeTask?: TaskObj
  onChangeFunc(event: React.FormEvent<HTMLTextAreaElement>): void
  onBlurFunc(): void
}> = ({ id, title, subject, onChangeFunc, onBlurFunc, activeTask }) => {
  const isAssignToType = title === 'Previously Assigned To'
  let textStyle = {
    border: 'solid 1px #c2c2c2',
    padding: '10px 10px',
    background: 'none'
  }
  const assignee = activeTask?.assigneeNames
  const isDisabled = false
  if (activeTask && isAssignToType) {
    textStyle = { ...textStyle, background: '#f4f4f4' }
  }

  return (
    <div id={id} className='task-add__container'>
      <div className='task-add__label'>{title}</div>
      <TextareaAutosize
        disabled={isDisabled}
        style={textStyle}
        maxRows={isAssignToType ? 8 : 3}
        className='side-bar-body__text task-add__textarea'
        name={isAssignToType ? 'assigneeNames' : 'subject'}
        value={isAssignToType ? assignee : subject}
        onChange={onChangeFunc}
        onBlur={onBlurFunc}
      />
    </div>
  )
}
